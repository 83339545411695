import Accordion from '../components/Accordion';

function AccordionPage() {
  const items = [
    {
      id: 'qasdj123lk2',
      label: 'TYPEMUSE Architecture: Frontend Engineering',
      content: `
In the frontend development of the TYPEMUSE system, I am engineering an infrastructure that rivals those deployed by large tech companies in Silicon Valley like Google, Meta, Amazon, Uber, Twitter (X), and Netflix, which are my primary inspirations—to seamlessly handle millions of users. At the heart of my approach is NEXT.js, the framework of choice for its exceptional support for optimized server-side rendering (SSR), dynamic static site generation, and overall performance. In this initial phase, the entire frontend ecosystem will be developed using TypeScript, ensuring robust type-checking and streamlined development workflows.

Embracing a microfrontend architecture, my strategy emphasizes modularity and the independent deployment of frontend components. While NEXT.js (leveraging React under the hood) is my primary framework today, I plan to expand my horizons in the future by incorporating Angular and even Vue in select microfrontends, thereby harnessing the unique strengths of each framework to address diverse application requirements.

For enterprise-level state management, I will integrate Redux along with Redux Toolkit Query (RTK Query) to ensure efficient data flow and scalability across the application. To secure user authentication and manage session persistence, I will implement a robust security framework that leverages cookies, sessions, and JSON Web Tokens (JWT), offering both flexibility and enhanced protection. Webpack, coupled with Webpack Module Federation, will empower module isolation and dynamic loading, further boosting performance and maintainability. Tailwind CSS will be utilized to deliver a streamlined, responsive, and visually engaging styling experience.

My static assets will be hosted on Amazon S3 and delivered efficiently via Amazon CloudFront through typemuse.com, ensuring a smooth user experience. Meanwhile, the vast majority of my dynamic Web 2.0 frontend—which encompasses numerous server-side rendered NEXT.js applications and a few Angular microfrontends—will run inside multiple Pods on a Kubernetes cluster. External traffic will be orchestrated through an Ingress NGINX Load Balancer, effectively distributing requests across Pods in multiple regions, while AWS Route 53 provides robust DNS resolution for global scalability and resilience.

This cutting-edge frontend architecture, built on NEXT.js, Kubernetes, NGINX, and AWS services, is meticulously designed to deliver a seamless, scalable, and high-performance experience for users around the world.
`,
    },
    {
      id: '89jek2jksok',
      label: 'TYPEMUSE Architecture: Backend + DevOps Engineering',
      content: `
In designing the backend architecture of the TYPEMUSE system, I am adopting a robust engineering strategy capable of scaling to millions of users. The backend will be implemented using a microservices architecture, providing scalability and facilitating future expansion of engineering teams. Additionally, I will be developing a custom library called MuseKit as a private npm package hosted on GitHub, installable across microservices using a dedicated GitHub access token.

For the API layer, I have decided to completely go with Express.js, ensuring a lightweight and efficient framework for building my microservices. In the initial version (v1), the entire codebase will be developed in TypeScript, standardizing my microservices with a single programming language. GraphQL will be integrated into specific services, enhancing the efficiency and flexibility of data querying and manipulation.

Adopting the Database Per Service pattern, I will heavily utilize PostgreSQL and, in certain instances, MongoDB, ensuring optimized data management tailored to each microservice. Furthermore, MySQL will be incorporated alongside PostgreSQL and MongoDB, with Sequelize and Mongoose serving as the ORM/ODM tools. Given the high volume of database transactions, optimistic concurrency control (OCC) will be implemented to safeguard data integrity and support extensive transactional workloads.

Asynchronous communication across microservices will be facilitated using distinct patterns: the event-driven pattern will utilize RabbitMQ as the primary message broker via AMQP, while synchronous Request/Response interactions will be managed via an Axios instance. This design ensures both responsiveness and reliability across the system.

A core component of my architecture is a custom-built API Gateway that serves as the single entry point for all external requests. From the frontend to the API Gateway, communication will occur via both HTTP request/response and WebSockets, ensuring real-time and reliable interactions. Within my MuseKit library, I have implemented dedicated gateway middleware that leverages Axios instances to validate a gateway token embedded in each request. This middleware ensures that only requests bearing a valid gateway token are routed to the appropriate microservice—any request bypassing the API Gateway will be immediately rejected. Communication from the API Gateway to the microservices also employs request/response protocols, supplemented by Socket.io-based WebSockets for real-time data exchange. Similarly, microservices will send responses back to the API Gateway through HTTP or WebSocket channels, maintaining a consistent, secure, and efficient flow of information across all layers.

I am adopting JIRA for project management to implement Agile Software Development Methodology, potentially with the Scrum framework. Continuous integration and delivery (CI/CD) pipelines will be managed using GitHub Actions or Jenkins, ensuring efficient deployment and maintenance processes.

For logging and monitoring, I will integrate Elasticsearch and Kibana for log indexing, searching, and comprehensive system monitoring. Winston, together with Winston Elasticsearch as a transporter, will be used for logging, while Prometheus and Grafana provide robust metrics and visualization.

Containerization with Docker and orchestration with Kubernetes will streamline communication and scalability between microservices. For local development, Docker Compose and Minikube will be utilized to simulate Kubernetes environments, while production deployment will leverage AWS EKS. PM2 will be employed for process management within Node.js applications.

Redis will be employed for caching and enhancing performance within the TYPEMUSE backend infrastructure. I am also going to utilize WebSockets like Socket.io to establish multiplayer connections and real-time messaging systems, cached with Redis for a high-end infrastructure. Additionally, I will implement either NGINX or Amazon's AWS Elastic Load Balancing (ELB) along with Kubernetes and Docker to ensure efficient load distribution and management of containerized applications.

Initially, the entire system will be implemented in TypeScript. However, after two years, I plan to transition towards a polyglot architecture, leveraging multiple programming languages—most likely Golang, Elixir, Java, and Rust—to handle specific use cases. For cross-language communication, I will adopt Protobuf and gRPC, utilizing the HTTP/2 protocol to enhance performance.

I was inspired by large tech companies in Silicon Valley like Google, Meta, Amazon, Uber, Netflix, Twitter and AirBnB — all of which employ massive monorepo architectures. For large-scale monorepo management, I'll be using either Bazel by Google or NX for the build system. I will also utilize various Amazon Web Services (AWS) such as EC2, Elastic Beanstalk, DynamoDB, and perhaps Lambda (λ), leveraging AWS compute services to run pods in containerized environments. Whether managed through AWS EKS, self-managed on EC2 instances, or even using Google Cloud Platform's Kubernetes Engine, the deployment will ensure scalability, reliability, and high performance.
`,
    },
    {
      id: 'zs72j202jl',
      label: 'Startup: Launch Date',
      content:
        'I am pleased to announce that the anticipated launch for TYPEMUSE is scheduled for the fall or summer of 2025. Most likely JUNE 1st of 2025! This represents a significant achievement in my development process, and I am dedicated to ensuring a successful and timely release of the product. I am working diligently to meet all objectives and deliver a high-quality solution that meets the needs and expectations of our users. As I progress towards the launch date, I will continue to provide updates and strive to exceed my targets for a successful introduction of TYPEMUSE to the world.',
    },
    {
      id: 'q3oms92jl278',
      label: 'CTO & Principal Software Engineer: Man behind TYPEMUSE?',
      content: `
        Hey! I'm Colson, and I am incredibly passionate about building large-scale web applications, tackling complex engineering challenges, and creating something truly special.  My love for microfrontends and microservices is driven by a desire to push the boundaries of what technology can achieve, much like the architecture of big companies like FAANG companies or Fortune 500 companies with multiple engineering teams managing multiple applications. TYPEMUSE, a subsidiary of parent company Syrosin Inc., is my vision to transform the typing experience into something fun, engaging, and incredibly addictive. It's not just about improving your typing speed, but about creating a multiplayer experience that brings joy and productivity together. I'm targeting students from elementary/middle school, high school to university, graduates, gamers, influencers, engineers, professionals working 9to5 jobs, and anyone who spends a significant amount of time on their computers. Imagine a platform where typing is not just a task, but a thrilling adventure with levels, XP, and so much more. I'm dedicated to making TYPEMUSE a robust and scalable platform that can handle millions of users, and I'm working tirelessly every day and night to bring this vision to life. Join me on this exciting journey, and let's make typing fun and productive together. Find me on Discord @ (.cols) :) I also want to give a shoutout to my brothers, engineers and business partners Samridda, whose Discord is @ (zephyr8848), and Shishir, whose Discord is @ (._spark). And also, I want to mention her, the girl I love who is my biggest inspiration in life. She is truly beautiful and gorgeous. I love her very much. Peace! 🕊️
        
      `,
    },
  ];

  return <Accordion items={items} />;
}

export default AccordionPage;
